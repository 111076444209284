import React, { useEffect, useState, useRef } from "react";
import FirstSection from "./first-section";
import SecondSection from "./second-section";
import ThirdSection from "./third-section";
import Filter from "./Filter";
import "./index.scss";
import moment from "moment";
import AxiosBase from "../../networkRequest/axiosBase";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import { PDFDocument } from "pdf-lib";
import { toWords } from "number-to-words";

export default function Dashboard(props) {
  const [filter, setFilter] = useState({
    dateRange: "day",
    from_date: moment().format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    currency: "AED",
    country: "ARE",
  });
  const [data, setData] = useState({
    order: {},
    warehouse: 0,
    customer: 0,
    rider: 0,
  });
  const [spinner, setSpinner] = useState(false);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [warehouse, setWarehouse] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [selectedFullWarehouse, setSelectedFullWarehouse] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [currentDownload, setCurrentDownload] = useState(0);
  const [groupOrder, setGroupOrder] = useState([]);
  const [pieChartData, setPieChartData] = useState([0, 0, 0]);

  const pdfRef = useRef();
  const sectionRefs = useRef([]);

  useEffect(() => {
    getData(
      filter.dateRange,
      filter.from_date,
      filter.to_date,
      filter.currency,
      filter.country
    );
  }, [selectedWarehouse]);

  const handledates = (values) => {
    const from_date = moment(values.startDate).format("YYYY-MM-DD");
    const to_date = moment(values.endDate).format("YYYY-MM-DD");
    getData(
      filter.dateRange,
      from_date,
      to_date,
      filter.currency,
      filter.country
    );
    setDate(values);
  };

  const handleClear = (value) => {
    if (value == "dateRange") {
      setFilter({
        ...filter,
        dateRange: "Today",
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      });
      const filterDate = moment().format("YYYY-MM-DD");
      getData("Today", filterDate, filterDate, filter.currency, filter.country);
      return true;
    }
    if (value == "currency") {
      setFilter({
        ...filter,
        currency: "AED",
      });
      getData(
        filter.dateRange,
        filter.from_date,
        filter.to_date,
        "AED",
        filter.country
      );
      return true;
    }
    if (value == "country") {
      setFilter({
        ...filter,
        country: "ARE",
      });
      getData(
        filter.dateRange,
        filter.from_date,
        filter.to_date,
        filter.currency,
        ""
      );
      return true;
    }
  };

  const handleFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDate({
      startDate: "",
      endDate: "",
    });
    if (name == "dateRange") {
      if (value == "custom") {
        setFilter({
          ...filter,
          dateRange: value,
          from_date: filter.from_date,
          to_date: filter.to_date,
        });
        return true;
      }
      const { from_date, to_date } = getDateRange(value);
      getData(value, from_date, to_date, filter.currency, filter.country);
      setFilter({
        ...filter,
        dateRange: value,
        from_date,
        to_date,
      });
      return true;
    }
    if (name == "currency") {
      getData(
        filter.dateRange,
        filter.from_date,
        filter.to_date,
        value,
        filter.country
      );
    }
    if (name == "country") {
      getData(
        filter.dateRange,
        filter.from_date,
        filter.to_date,
        filter.currency,
        value
      );
    }
    setFilter({
      ...filter,
      [name]: value,
    });
    return true;
  };

  const chunkArray = (array, groupSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += groupSize) {
      result.push(array.slice(i, i + groupSize));
    }
    return result;
  };

  const getData = (dateRange, from_date, to_date, currency, country) => {
    if (!selectedWarehouse) {
      return;
    }
    setSpinner(true);
    AxiosBase.get(
      `/admin/admin-dashboard?` +
        `dateRange=${dateRange}&dateFrom=${from_date}&dateTo=${to_date}&dateField=updatedAt&warehouse_id=${selectedWarehouse}`
    ).then((response) => {
      setData(response?.data || null);
      const largeArray = response?.data?.completed_order || [];
      const groupSize = 30;
      const groupedArray = chunkArray(largeArray, groupSize);
      setGroupOrder(groupedArray);
      setSpinner(false);
    });
    AxiosBase.get(
      `/admin/admin-pie-chart?` +
        `dateFrom=${from_date}&dateTo=${to_date}&warehouse_id=${selectedWarehouse}`
    ).then((response) => {
      setPieChartData(
        response?.data?.data && response?.data?.data.length
          ? [
              response?.data?.data[0]?.cash || 0,
              response?.data?.data[0]?.wallet || 0,
              response?.data?.data[0]?.online || 0,
            ]
          : [0, 0, 0]
      );
    });
  };

  const getAllWarehouse = () => {
    AxiosBase.get(
      `/admin/warehouse?pageNo=0&rowsPerPage=1000000&sortBy=_id&sortOrder=asc`
    ).then((response) => {
      setWarehouse(response.data.data);
      setSelectedWarehouse(response.data.data[0]._id);
      setSelectedFullWarehouse(response.data.data[0]);
    });
  };

  useEffect(() => {
    getAllWarehouse();
  }, []);

  const getDateRange = (value) => {
    let from_date = moment().format("YYYY-MM-DD");
    let to_date = moment().format("YYYY-MM-DD");
    if (value == "day") {
      from_date = moment().format("YYYY-MM-DD");
      to_date = moment().format("YYYY-MM-DD");
    } else if (value == "last-day") {
      from_date = moment().subtract(1, "day").format("YYYY-MM-DD");
      to_date = moment().subtract(1, "day").format("YYYY-MM-DD");
    } else if (value == "week") {
      from_date = moment().startOf("isoWeek").format("YYYY-MM-DD");
      to_date = moment().endOf("isoWeek").format("YYYY-MM-DD");
    } else if (value == "month") {
      from_date = moment().startOf("month").format("YYYY-MM-DD");
      to_date = moment().endOf("month").format("YYYY-MM-DD");
    } else if (value == "year") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    } else if (value == "custom") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    }
    return { from_date, to_date };
  };

  const downloadInvoice = () => {
    setDownloadLoader(true);
    AxiosBase.get(
      `/admin/order/download-multiple-invoice?dateFrom=${filter?.from_date}&toDate=${filter?.to_date}&warehouse_id=${selectedWarehouse}`
    ).then((response) => {
      const resultByte = response?.data?.pdf?.data;
      var bytes = new Uint8Array(resultByte);
      var blob = new Blob([bytes], { type: "application/pdf" });

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Order-Invoice-${filter?.from_date}-to-${filter?.to_date}.pdf`;
      link.click();
      setDownloadLoader(false);
      return toast("Invoice downloaded!", {
        type: "success",
        autoClose: 2000,
      });
    });
  };

  const generatePDF = () => {
    setDownloadLoader(true);
    setTimeout(async () => {
      const pdfs = [];
      const options = {
        margin: 0.5,
        image: { type: "jpeg", quality: 0.95 },
        html2canvas: { scale: 1, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };

      // Iterate through dynamic sections to generate individual PDFs
      for (let i = 0; i < groupOrder.length; i++) {
        const sectionElement = sectionRefs.current[i];
        const pdf = await generateSinglePdf(sectionElement, options);
        setCurrentDownload(i + 1);
        pdfs.push(pdf);
      }

      // Merge all PDFs into one
      const mergedPdf = await mergePdfs(pdfs);

      // Download the merged PDF
      const blob = new Blob([mergedPdf], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "customer-bill.pdf";
      link.click();
      setCurrentDownload(0);
      setDownloadLoader(false);
    }, 200);
  };

  const generateSinglePdf = (element, options) => {
    return new Promise((resolve) => {
      html2pdf()
        .from(element)
        .set(options)
        .outputPdf("arraybuffer")
        .then(resolve);
    });
  };

  const mergePdfs = async (pdfBuffers) => {
    const mergedPdf = await PDFDocument.create();
    for (const pdfBuffer of pdfBuffers) {
      const pdf = await PDFDocument.load(pdfBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
    return await mergedPdf.save();
  };

  const getTotalAmount = (order) => {
    const total_amount =
      Number(order?.amount || 0) -
      Number(order?.pick_delivery_charges || 0) -
      Number(order?.drop_delivery_charges || 0) -
      Number(order?.tax_amount || 0) +
      Number(order?.discount || 0) +
      Number(order?.offer_discount) -
      Number(order?.additional_amount || 0);
    return total_amount;
  };

  return (
    <div>
      <h1 className="merchant_title">Dashboard</h1>
      <div className="dashboard-section">
        <div className="dashboard-section-main">
          <Filter
            handleFilter={handleFilter}
            handleClear={handleClear}
            filter={filter}
            handledates={handledates}
            warehouse={warehouse}
            selectedWarehouse={selectedWarehouse}
            setSelectedWarehouse={setSelectedWarehouse}
            setSelectedFullWarehouse={setSelectedFullWarehouse}
          />
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <FirstSection data={data} filter={filter} />
              <a className="download-invoice-btn" onClick={generatePDF}>
                {downloadLoader
                  ? `${currentDownload}/${groupOrder.length} Downloading...`
                  : "Download all Invoice"}
              </a>
              <SecondSection
                data={
                  data?.chartData && data?.chartData.length
                    ? data?.chartData[0].order_detail
                    : []
                }
                filter={filter}
              />

              <ThirdSection data={pieChartData} />
            </>
          )}
        </div>
      </div>

      {downloadLoader && (
        <div ref={pdfRef}>
          {groupOrder &&
            groupOrder?.length > 0 &&
            groupOrder.map((groupItem, index) => (
              <div
                className="offscreen no-space-collapse"
                key={index}
                ref={(el) => (sectionRefs.current[index] = el)}
              >
                {groupItem.map((order, ind) => (
                  <>
                    <div
                      class={`invoice-heading ${
                        ind === 0 ? "avoid-break-page" : ""
                      }`}
                    >
                      <b>
                        Tax Invoice-
                        {order?.bill?.bill_no || "N/A"}
                      </b>
                    </div>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="company-detail">
                            <div className="row cell-section">
                              <div className="col">
                                <h4>ClothiQ Solutions Pvt. Ltd.</h4>
                                <span>{selectedFullWarehouse?.address}</span>
                                <br />
                                <span>
                                  Phone No: +{selectedFullWarehouse?.mobile_no}
                                </span>
                                <br />
                                <span>GSTIN: 08CHMPR1675R1Z8</span>
                                <br />
                              </div>
                            </div>
                          </td>
                          <td class="order-no-section">
                            <div class="row cell-section">
                              <div class="col">
                                <span>Order No:</span>
                                <br />
                                <span>
                                  <b>{order?.order_id}</b>
                                </span>
                                <br />
                                <span>Payment Status:</span>
                                <br />
                                <span>
                                  <b>
                                    {order?.payment_status === "PAID"
                                      ? "Paid"
                                      : "Pending"}
                                  </b>
                                </span>
                                <br />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row cell-section">
                              <div class="col">
                                <span>Invoice Date:</span>
                                <br />
                                <span>{moment().format("DD-MM-YYYY")}</span>
                                <br />
                                <span>Delivery Date:</span>
                                <br />
                                <span>
                                  {moment(order?.drop_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                                <br />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            <div class="row cell-section bill-to-section">
                              <div class="col">
                                <span>Bill To</span>
                                <br />
                                <span>{order?.customer_id?.name}</span>
                                <br />
                                <span>{order?.address}</span>
                                <br />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="items-table" style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Quantity</th>
                          <th>Weight</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.services
                          .filter((x) => x?.type !== "DRY_CLEAN")
                          .map((item, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{item?.name}</td>
                              <td>{item?.quantity} Item</td>
                              <td>
                                {item?.weight ? `${item?.weight} kg` : "N/A"}
                              </td>
                              <td>
                                <label
                                  style={{ textDecoration: "line-through" }}
                                >
                                  ₹{Number(item?.total_amount || 0).toFixed(2)}
                                </label>
                                &nbsp;&nbsp;
                                <label>
                                  ₹${Number(item?.amount).toFixed(2)}
                                </label>
                              </td>
                            </tr>
                          ))}
                        {order.services
                          .filter(
                            (service) =>
                              service?.type === "DRY_CLEAN" &&
                              service.selected_items
                          )
                          .flatMap((service) =>
                            service.selected_items.map((item, index) => {
                              const itemNumber =
                                order.services.filter(
                                  (s) => s?.type !== "DRY_CLEAN"
                                ).length +
                                index +
                                1;

                              return (
                                <tr key={`${service.type}-${index}`}>
                                  <td>{itemNumber}</td>
                                  <td>{item?.name}</td>
                                  <td>{item?.quantity} Item</td>
                                  <td>N/A</td>
                                  <td>
                                    <label
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      ₹
                                      {Number(item?.total_amount || 0).toFixed(
                                        2
                                      )}
                                    </label>
                                    &nbsp;&nbsp;
                                    <label>
                                      ₹${Number(item?.amount).toFixed(2)}
                                    </label>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        <tr>
                          <td colSpan="3">
                            <div class="row cell-section">
                              <div class="col">
                                <span>
                                  Payment Mode: {order?.payment_mode || "Cash"}
                                </span>
                                <br />
                                <span>Invoice Amount In Words</span>
                                <br />
                                <span>
                                  <b>{toWords(order?.amount || 0)}</b>
                                </span>
                              </div>
                            </div>
                          </td>
                          <td colSpan="2">
                            <div class="row cell-section">
                              <div class="col total-amount-section">
                                <span>
                                  Amount:{" "}
                                  <b>
                                    ₹{Number(getTotalAmount(order)).toFixed(2)}
                                  </b>
                                </span>
                                <br />
                                <span>
                                  Discount:{" "}
                                  <b>
                                    ₹
                                    {(
                                      Number(order?.discount || 0) +
                                      Number(order?.offer_discount || 0)
                                    ).toFixed(2)}
                                  </b>
                                </span>
                                <br />
                                <span>
                                  Additional Amount:{" "}
                                  <b>
                                    ₹
                                    {Number(
                                      order?.additional_amount || 0
                                    ).toFixed(2)}
                                  </b>
                                </span>
                                <br />
                                <span>
                                  Tax Amount(CGST-9%):{" "}
                                  <b>
                                    ₹{(order?.tax_amount / 2 || 0).toFixed(2)}
                                  </b>
                                </span>
                                <br />
                                <span>
                                  Tax Amount(SGST-9%):{" "}
                                  <b>
                                    ₹{(order?.tax_amount / 2 || 0).toFixed(2)}
                                  </b>
                                </span>
                                <br />
                                <span>
                                  Delivery Charges:{" "}
                                  <b>
                                    ₹
                                    {(
                                      (order?.pick_delivery_charges || 0) +
                                      (order?.drop_delivery_charges || 0)
                                    ).toFixed(2)}
                                  </b>
                                </span>
                                <br />
                                <span>
                                  Total Amount:{" "}
                                  <b>₹{order?.amount.toFixed(2)}</b>
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr style={{ pageBreakInside: "avoid" }}>
                          <td colSpan="3">
                            <div class="row cell-section">
                              <div class="col">
                                <span>
                                  <b>Terms and Conditions</b>
                                </span>
                                <br />
                                <span>
                                  Thankyou for doing business with us.
                                </span>
                              </div>
                            </div>
                          </td>
                          <td colSpan="2">
                            <div class="row cell-section">
                              <div class="col signature-section">
                                <span>For: ClothiQ Solutions Pvt. Ltd.</span>
                                <br />
                                <img
                                  src="/images/signature.png"
                                  width="20%"
                                  alt="sign"
                                />
                                <br />
                                <span>
                                  <b>Authorized Signatory</b>
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <br />
                  </>
                ))}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
