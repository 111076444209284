import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import DateRangeComp from "../datepicker";
import "./Filter.scss";

export default function Filter(props) {
  return (
    <div className="dashboard-filter">
      <h5 className="dashboard-filter-heading">Key indicators</h5>
      <div>
        <FormControl sx={{ m: 1, minWidth: 220 }} size="medium">
          <InputLabel id="warehouse-select-small">Warehouse</InputLabel>
          <Select
            value={props.selectedWarehouse}
            style={{ width: "100%" }}
            id="warehouse-select-small"
            label="Warehouse"
            onChange={(e) => {
              props.setSelectedWarehouse(e.target.value);
              const ind = props?.warehouse.find(
                (x) => x._id === e.target.value
              );
              props.setSelectedFullWarehouse(ind);
            }}
          >
            <MenuItem key="all" value="">
              Select Warehouse
            </MenuItem>
            {props.warehouse.map((w) => {
              return (
                <MenuItem key={w._id} value={w._id}>
                  {w.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Date Range</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={props.filter.dateRange}
            label="Date Range"
            name="dateRange"
            onChange={props.handleFilter}
          >
            <MenuItem value={"day"}>Today</MenuItem>
            <MenuItem value={"last-day"}>Yesterday</MenuItem>
            <MenuItem value={"week"}>This Week</MenuItem>
            <MenuItem value={"month"}>This Month</MenuItem>
            <MenuItem value={"year"}>This Year</MenuItem>
            <MenuItem value={"custom"}>Custom Range</MenuItem>
          </Select>
        </FormControl>
        {props.filter.dateRange == "custom" && (
          <DateRangeComp
            handledates={props.handledates}
            position="bottom right"
            style={{ display: "inline-block", marginTop: 10, marginRight: 4 }}
            open={true}
          />
        )}
      </div>
    </div>
  );
}
